<template>
    <div class="order-product-info">
        <el-button v-if="this.pickedup == true" type="danger" size="mini" style="margin-bottom: 20px" @click="openDialog(1)">补发</el-button>
        <el-button v-if="this.pickedup == true" type="danger" size="mini" style="margin-bottom: 20px" @click="openDialog(2)">退换货</el-button>
        <el-button v-if="this.pickedup == false" type="danger" size="mini" style="margin-bottom: 20px" @click="openDialog(3)">改商品</el-button>
        <el-table border :data="orderProductInfo" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="externalOrderNo" label="外部子单号"></el-table-column>
            <el-table-column prop="externalMainOrderNo" label="外部主单号"></el-table-column>
            <el-table-column prop="sourceOrderMainNo" label="订单来源主单号"></el-table-column>
            <el-table-column property="status" label="订单状态" :formatter="formatOrderStatus"></el-table-column>
            <el-table-column prop="deliveryTime" label="配送时间">
               <template slot-scope="scope">
                   <span>{{formatDate(scope.row.deliveryTime)}}</span>
               </template>
            </el-table-column>
            <el-table-column prop="pickupTime" label="提货时间">
               <template slot-scope="scope">
                   <span>{{formatDate(scope.row.pickupTime)}}</span>
               </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称"></el-table-column>
            <el-table-column prop="productType" label="产品类型" :formatter="formatProductType"></el-table-column>
            <el-table-column prop="productNum" label="产品数量"></el-table-column>
            <el-table-column prop="payBalancePerAmount" label="余额支付"></el-table-column>
            <el-table-column prop="payWechatPerAmount" label="微信支付"></el-table-column>
            <el-table-column prop="payAmount" label="实付金额"></el-table-column>
            <el-table-column prop="cashAmount" label="最终支付金额(不含余额部分)"></el-table-column>
            <el-table-column prop="isPromotion" label="是否参与促销" :formatter="formatIsPromotion"></el-table-column>
            <el-table-column prop="promotionId" label="促销id"></el-table-column>
            <el-table-column prop="promotionName" label="促销名称"></el-table-column>
            <el-table-column prop="suitId" label="套装Id"></el-table-column>
            <el-table-column prop="suitName" label="套装名称"></el-table-column>
        </el-table>
        <el-dialog :fullscreen="true"
                :title="dialogTitle"
                :visible="changeShow" @close="handleClose">
            <el-form :model="form" :inline="true" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="期望配送日期" v-if="editProShow">
                    <el-date-picker v-model="form.deliverDateExpect" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </el-form>
            <p v-if="oldProShow">原商品</p><br>
            <el-table border :data="oldProducts" size="mini" v-if="oldProShow">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="BarCode" prop="productCbarcode"></el-table-column>
                <el-table-column label="商品名称" prop="productName"></el-table-column>
                <el-table-column label="原商品数量" prop="productNum"></el-table-column>
                <el-table-column label="改后商品数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.reduceCount" :min=0 :max="scope.row.productNum"></el-input-number>
                        <span style="color:red">*请注意这里，原商品保留几个</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-divider v-if="oldProShow"></el-divider>
            <p>新商品</p>
            <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="addProduct()">加商品</el-button>
            <el-table :data="newProducts" border size="mini" >
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="商品选择">
                    <template slot-scope="scope" >
                        <el-select size="mini" v-model="scope.row.saleId" @change="selectProduct($event,scope.row)" filterable clearable placeholder="选择商品">
                            <el-option
                                    v-for="item in productList"
                                    :key="item.cbarcode"
                                    :label="item.value + '(' + item.cbarcode + ')'"
                                    :value="item.cbarcode">
                            </el-option>
                        </el-select>
<!--                        <el-select size="mini" v-model="scope.row.saleId" v-if="scope.row.type==2" clearable placeholder="选择套装">-->
<!--                            <el-option-->
<!--                                    v-for="item in groupList"-->
<!--                                    :key="item.groupId"-->
<!--                                    :label="item.groupName"-->
<!--                                    :value="item.groupId">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.saleCount" :min=1 :max=999></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="delProduct(scope.$index)" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="changeShow = false">取 消</el-button>
                <el-button type="primary" size="mini" @click="saveChange()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                orderNo:this.$route.params.externalOrderNo,
                shopName:this.$route.params.shopName,
                productList:[],
                groupList:[],
                oldProducts:[],
                newProducts:[],
                changeShow:false,
                oldProShow:false,
                editProShow:true,
                dialogTitle:"",
                form:{
                    orderNo:"",
                    optType:"",
                    oldValue:"",
                    newValue:"",
                    deliverDateExpect:""
                }
            }
        },

        props:['orderProductInfo','pickedup'],

        mounted(){
            this.getProducts();
        },

        methods:{
            returnHistory(){
                window.history.go(-1)
            },
            formatOrderStatus: function (row, column) {
                var status = row.status;
                if (status == 0) {
                    return "已取消";
                }
                if(status == 5){
                    return "待支付";
                }
                if(status == 10){
                    return "已支付";
                }
                if (status == 15) {
                    return "部分配送";
                }
                if(status == 20){
                    return "全部配送";
                }
                if(status == 25){
                    return "部分发货";
                }
                if(status == 30){
                    return "已发货";
                }
                if(status == 35){
                    return "已送达";
                }
                if(status == 45){
                    return "交易关闭";
                }
            },
            formatProductType: function (row, column) {
                if(row.productType == 1){
                    return  "spu";
                }
                if(row.productType == 2){
                    return  "虚拟卡";
                }
                if(row.productType == 3){
                    return  "物品";
                }

            },
            formatDate(datetime) {
                if(datetime == null){
                    return;
                }
                var date = new Date(datetime);
                var year = date.getFullYear(),
                    month = ("0" + (date.getMonth() + 1)).slice(-2),
                    sdate = ("0" + date.getDate()).slice(-2),
                    hour = ("0" + date.getHours()).slice(-2),
                    minute = ("0" + date.getMinutes()).slice(-2),
                    second = ("0" + date.getSeconds()).slice(-2);
                // 拼接
                var result = year + "-"+ month +"-"+ sdate +" "+ hour +":"+ minute +":" + second;
                // 返回
                return result;
            },
            formatIsPromotion: function (row, column){
                if(row.isPromotion == 1){
                    return  "参与";
                }
                if(row.isPromotion == 2){
                    return  "未参与";
                }
            },
            openDialog(type){
                this.initOldProducts();
                this.form.optType = type;
                this.changeShow = true;
                if(type == 2){
                    this.oldProShow = true;
                    this.dialogTitle = "退换货";
                } else if(type == 1){
                    this.oldProShow = false;
                    this.dialogTitle = "补发";
                }else{
                    this.oldProShow = true;
                    this.editProShow = false;
                    this.dialogTitle = "改商品";
                }
            },
            getProducts(){
                this.$apiGet('lechun-csms/bdProduct/getProducts').then(res => {
                    this.productList = res;
                });
            },
            selectProduct(event,row){
                console.log("event",event);
                this.productList.forEach((pro) => {
                    if(pro.cbarcode == event){
                        row.saleId = pro.cguid;
                        row.saleName = pro.cname;
                        row.sellPrice = 0;
                        row.iservice = pro.iservice;
                    }
                });
                console.log("newProducts",this.newProducts);
            },
            initOldProducts(){
                this.oldProducts = [];
                this.newProducts = [];
                for(var i = 0; i < this.orderProductInfo.length; i++){
                    this.oldProducts.push({
                      productCbarcode:this.orderProductInfo[i].productCbarcode,
                      productName:this.orderProductInfo[i].productName,
                      productNum:this.orderProductInfo[i].productNum,
                      reduceCount:0
                    });
                }
            },
            addProduct(){
                this.newProducts.push({
                    saleId:'',
                    saleName:'',
                    cbarcode:'',
                    saleCount:1,
                    type:1
                })
            },
            delProduct(index){
                this.newProducts.splice(index,1)
            },
            saveChange(){
                if(this.editProShow && (this.form.deliverDateExpect == null || this.form.deliverDateExpect == '')){
                    this.$notify({
                        title: '警告',
                        message: '请输入期望配送日期',
                        type: 'warning'
                    });
                    return false;
                }
                this.form.orderNo = this.orderNo;
                // 校验oldProducts
                var oldProductStr = "";
                for(var i = 0; i < this.oldProducts.length; i++){
                    if(this.oldProducts[i].reduceCount > 0){
                        oldProductStr = oldProductStr
                            + this.oldProducts[i].productCbarcode + ","
                            + this.oldProducts[i].productName + ","
                            + this.oldProducts[i].reduceCount + "|";
                    }
                }
                this.form.oldValue = oldProductStr;
                // 校验newProducts
                var newProductStr = "";
                for(var i = 0; i < this.newProducts.length; i++){
                    if(this.newProducts[i].type == 1){
                        for(var j = 0; j < this.productList.length; j++){
                            if(this.productList[j].barCode == this.newProducts[i].saleId){
                                this.newProducts[i].saleName = this.productList[j].proName;
                                break;
                            }
                        }
                    } else if(this.newProducts[i].type == 2){
                        for(var j = 0; j < this.groupList.length; j++){
                            if(this.groupList[j].groupId == this.newProducts[i].saleId){
                                this.newProducts[i].saleName = this.groupList[j].groupName;
                                break;
                            }
                        }
                    }else if(this.newProducts[i].type == 3){
                         for(var j = 0; j < this.groupList.length; j++){
                             if(this.groupList[j].groupId == this.newProducts[i].saleId){
                                 this.newProducts[i].saleName = this.groupList[j].groupName;
                                 break;
                             }
                         }
                     }

                    if(this.newProducts[i].saleCount > 0){
                        newProductStr = newProductStr
                            + this.newProducts[i].saleId + ","
                            + this.newProducts[i].saleName + ","
                            + this.newProducts[i].saleCount + ","
                            + this.newProducts[i].type + "|";
                    }
                }
                this.form.newValue = newProductStr;
                if(this.form.optType == 3){
                    this.$apiGet('lechun-csms/orderApi/editOrderProducts',this.form).then(res=>{
                        this.$message({
                            showClose: true,
                            message: res,
                            type: 'success'
                        });
                        this.oldProShow = false;
                    });
                }else{
                    this.$apiGet('lechun-csms/apply/createOrderOperation',this.form).then(res=>{
                        this.$message({
                            showClose: true,
                            message: res,
                            type: 'success'
                        });
                        this.changeShow = false;
                    });
                }
            },
            handleClose(){
                this.changeShow = false;
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .order-product-info{
        margin-top: 20px;
        .red .el-form-item__label{
            color: red;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag{
            width: 100%;
            height: 32px;
        }
        .el-avatar{
            display: block;
            margin: 0 auto;
        }
    }
</style>
