<template>
    <div class="order-detail-info">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>订单信息</span>
                <div style="float: right;">
                    <el-button v-if="this.orderDetailInfo.orderFlowSign != 1" type="primary" size="mini" @click="orderFlow()">流转</el-button>
                    <el-button v-if="this.orderDetailInfo.orderFlowSign != 1" type="primary" size="mini" @click="setRemark()">备注拦单</el-button>
                </div>
            </div>
            <el-descriptions class="margin-top" :column="3" size="small" border>
                <el-descriptions-item label="店铺">
                    {{orderDetailInfo.shopName}}
                </el-descriptions-item>
                <el-descriptions-item label="微信主单号">
                    {{orderDetailInfo.orderMainNo}}
                </el-descriptions-item>
                <el-descriptions-item label="子单号/外部订单号">
                    {{orderDetailInfo.orderNo}}
                </el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    {{orderDetailInfo.orderStatusName}}
                </el-descriptions-item>
                <el-descriptions-item label="订单类型">
                    {{orderDetailInfo.orderTypeName}}
                </el-descriptions-item>
                <el-descriptions-item label="订单分类">
                    {{orderDetailInfo.orderSonTypeName}}
                </el-descriptions-item>
                <el-descriptions-item label="冷链/常温">
                    {{orderDetailInfo.orderIsCwName}}
                </el-descriptions-item>
                <el-descriptions-item label="是否流转">
                    {{orderDetailInfo.orderFlowSignName}}
                </el-descriptions-item>
                <el-descriptions-item label="流转异常原因">
                    {{orderDetailInfo.flowErroMessage}}
                </el-descriptions-item>
                <el-descriptions-item label="是否挂起">
                    {{orderDetailInfo.isPending == 1 ? '是' : '否'}}
                </el-descriptions-item>
                <el-descriptions-item label="微信订单来源">
                    {{orderDetailInfo.orderSourceName}}
                </el-descriptions-item>
                <el-descriptions-item label="微信来源主单号">
                    {{orderDetailInfo.sourceOrderMainNo}}
                </el-descriptions-item>
                <el-descriptions-item label="微信订单分类">
                    {{orderDetailInfo.orderClassName}}
                </el-descriptions-item>
                <el-descriptions-item label="购买人昵称">
                    <p v-if="isWeixinOrder && orderDetailInfo.buyerId != 'csms-manual-order-creator'" @click="toBuyer(orderDetailInfo.buyerId)" style="text-decoration:underline;color:#0000ff;">{{orderDetailInfo.buyerName}}</p>
                    <p v-if="!(isWeixinOrder && orderDetailInfo.buyerId != 'csms-manual-order-creator')">{{orderDetailInfo.buyerName}}</p>
                </el-descriptions-item>
                <el-descriptions-item label="购买人头像">
                    <!-- TODO -->
<!--                    <el-avatar shape="square" :size="50" src="{{orderDetailInfo.buyerAvatar}}"></el-avatar>-->
                </el-descriptions-item>
                <el-descriptions-item label="支付时间">
                    {{orderDetailInfo.payTime}}
                </el-descriptions-item>
                <el-descriptions-item label="备注">
                    {{orderDetailInfo.remark}}
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>订单商品</span>
                <div style="float: right;">
                    <el-button v-if="this.pickedup == true" type="primary" size="mini" @click="openDialogBuFa()">补发</el-button>
<!--                    <el-button v-if="this.pickedup == true" type="primary" size="mini" @click="openDialog(2)">换货</el-button>-->
                    <el-button v-if="this.pickedup == false" type="primary" size="mini" @click="openDialogGaiPin()">修改商品</el-button>
                </div>
            </div>
            <el-descriptions class="margin-top" :column="1" size="small" border>
                <!--                <el-descriptions-item v-if="proPromotion.length>0" label="促销">-->
                <!--                    <el-descriptions class="margin-top" :column="1" size="small">-->
                <!--                        <el-descriptions-item label="促销">-->
                <!--                            <el-tag size="mini">{{proPromotion[0].promotionName}}</el-tag>-->
                <!--                            <el-tag size="mini" type="info" v-for="item in proPromotion" >{{item.productName}}</el-tag>-->
                <!--                        </el-descriptions-item>-->
                <!--                    </el-descriptions>-->
                <!--                </el-descriptions-item>-->
                <!--                <el-descriptions-item v-if="proGroup.length>0" label="套装">-->
                <!--                    <el-descriptions class="margin-top" :column="1" size="small">-->
                <!--                        <el-descriptions-item label="促销">-->
                <!--                            <el-tag size="mini">{{proGroup[0].suitName}}</el-tag>-->
                <!--                            <el-tag size="mini" type="info" v-for="item in proGroup" >{{item.productName}}</el-tag>-->
                <!--                        </el-descriptions-item>-->
                <!--                    </el-descriptions>-->
                <!--                </el-descriptions-item>-->
                <el-descriptions-item label="套装信息">
                    <el-tag type="success" size="mini" v-for="item in this.orderDetailInfo.orderGroups">{{item.groupName}} x {{item.groupNum}}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="商品明细">
                    <el-tag type="success" size="mini" v-for="item in this.orderDetailInfo.orderProducts">{{item.productName}} x {{item.productNum}}</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>订单支付信息</span>
<!--                <div style="float: right;">
                    <el-button v-if="this.isWeixinOrder" type="primary" size="mini" @click="toRefund()">退款</el-button>
                </div>-->
            </div>
            <el-descriptions class="margin-top" :column="3" size="small" border>
                <el-descriptions-item label="订单金额">
                    {{orderDetailInfo.orderAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="运费">
                    {{orderDetailInfo.freight}}
                </el-descriptions-item>
                <el-descriptions-item v-if="this.isWeixinOrder" label="现金支付">
                    {{orderDetailInfo.cashAmount}}
                </el-descriptions-item>
                <el-descriptions-item v-if="!this.isWeixinOrder" label="支付金额">
                    {{orderDetailInfo.payAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="优惠券">
                    {{orderDetailInfo.orderCouponAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="使用点数" :span="3">
                    {{orderDetailInfo.specialCardPoint}}
                </el-descriptions-item>
                <el-descriptions-item label="余额支付总额" :span="3">
                    {{orderDetailInfo.balanceAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="余额储值部分">
                    {{orderDetailInfo.balanceStoreAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="余额赠送部分">
                    {{orderDetailInfo.balanceFreeAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="余额礼品卡部分">
                    {{orderDetailInfo.balanceGiftAmount}}
                </el-descriptions-item>
                <el-descriptions-item label="微信交易号">
                    {{orderDetailInfo.wechatPaySerialNo}}
                </el-descriptions-item>
                <el-descriptions-item label="商户单号">
                    {{orderDetailInfo.wechatPayTradeNo}}
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>配送信息</span>
                <div style="float: right;">
                    <el-button v-if="this.orderDetailInfo.orderFlowSign != 1" @click="editAddress" style="float: right;" size="mini" type="primary">修改地址</el-button>
                    <el-button v-if="this.orderDetailInfo.orderFlowSign != 1" @click="toEditDeliveryTime()" style="float: right;" size="mini" type="primary">修改配送日期</el-button>
                </div>
            </div>
            <el-descriptions class="margin-top" :column="2" size="small" border>
                <el-descriptions-item label="提货日期">
                    {{orderDetailInfo.pickupDate}}
                </el-descriptions-item>
                <el-descriptions-item label="配送日期">
                    {{orderDetailInfo.deliverDate}}
                </el-descriptions-item>
                <el-descriptions-item label="发货仓">
                    {{orderDetailInfo.storeName}}
                </el-descriptions-item>
                <el-descriptions-item label="收货人">
                    {{orderDetailInfo.consigneeName}}
                </el-descriptions-item>
                <el-descriptions-item label="收货电话">
                    {{orderDetailInfo.consigneePhone}}
                </el-descriptions-item>
                <el-descriptions-item label="详细地址">
                    {{orderDetailInfo.consigneeAddrEntire}}
                </el-descriptions-item>
                <el-descriptions-item label="物流公司">
                    {{orderDetailInfo.wayName}}
                </el-descriptions-item>
                <el-descriptions-item label="物流单号">
                    {{orderDetailInfo.wayNo}}
                </el-descriptions-item>
                <el-descriptions-item v-if="orderDetailInfo.wayNo != null && orderDetailInfo.wayNo != ''" label="物流信息">
                    <el-timeline>
                        <el-timeline-item
                            v-for="(activity, index) in routers"
                            :key="index"
                            :timestamp="activity.time">
                            {{activity.context}}
                        </el-timeline-item>
                    </el-timeline>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card class="box-card" header="退款记录">
            <template>
                <el-table size="mini" :data="orderDetailInfo.orderRefundDetails" style="width: 100%">
                    <el-table-column prop="refundNo" label="退款单号" width="180"></el-table-column>
                    <el-table-column prop="refundType" label="退款类型" width="180"></el-table-column>
                    <el-table-column prop="afterSaleType" label="售后类型"></el-table-column>
                    <el-table-column prop="refundAmountCheck" label="退款金额"></el-table-column>
                    <el-table-column prop="refundStatus" label="状态"></el-table-column>
                    <el-table-column prop="refundReason" label="原因"></el-table-column>
                    <el-table-column prop="createId" label="操作人"></el-table-column>
                    <el-table-column prop="createTime" label="申请时间"></el-table-column>
                </el-table>
            </template>
        </el-card>
        <el-card class="box-card" header="订单修改记录">
            <el-descriptions class="margin-top" :column="3" size="small" border>
            </el-descriptions>
        </el-card>
        <!-- 修改配送日期弹窗 -->
        <el-dialog
            title="修改配送日期"
            :visible="orderEditDate"
            :before-close="handleClose">
            <el-form ref="form" :model="formDataDelivery" label-width="80px" class="draw-box">
                <el-form-item label="子订单号" required>
                    <el-input v-model="formDataDelivery.externalOrderNo" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="配送日期">
                    <el-date-picker v-model="formDataDelivery.deliverDate" type="date" placeholder="选择日期"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="deliveryDateSubmit">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 修改地址弹窗 -->
        <el-dialog
            title="修改地址信息(都是必填项)"
            :visible="orderEditAddr"
            :before-close="handleClose">
            <el-form ref="form" :model="formDataAddr" label-width="80px" class="draw-box">
                <el-form-item label="子订单号" required>
                    <el-input v-model="formDataAddr.externalOrderNo" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="收件人" required>
                    <el-input v-model="formDataAddr.consigneeName"></el-input>
                </el-form-item>
                <el-form-item label="收件手机" required>
                    <el-input v-model="formDataAddr.consigneeMobile" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="地址选择" required>
                    <el-cascader
                        ref="cascaderAddr"
                        placeholder="请选择省市区"
                        v-model="cityValue"
                        :options="addressCityList"
                        :props="{value:'Id',label:'Name',children:'VoList'}"
                        @change="handleCasChange"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" required>
                    <el-input v-model="formDataAddr.consigneeAddress"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addressSubmit">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 补发弹窗 -->
        <el-dialog :fullscreen="true" title="补发" :visible="bufaShow" @close="handleClose">
            <el-form :model="formDataProduct" :inline="true" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="补发原因" required>
                    <el-select v-model="formDataProduct.consigneeRemark" placeholder="请选择退换货/补货类型">
                        <el-option label="错发" value="错发"></el-option>
                        <el-option label="漏发" value="漏发"></el-option>
                        <el-option label="内物破损" value="内物破损"></el-option>
                        <el-option label="产品质量问题" value="产品质量问题"></el-option>
                        <el-option label="物流包装破损" value="物流包装破损"></el-option>
                        <el-option label="时效延误" value="时效延误"></el-option>
                        <el-option label="脱冷" value="脱冷"></el-option>
                        <el-option label="未按预约时间配送" value="未按预约时间配送"></el-option>
                        <el-option label="疫情停派补发" value="疫情停派补发"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收件人" required>
                    <el-input v-model="formDataProduct.consigneeName"></el-input>
                </el-form-item>
                <el-form-item label="收件手机" required>
                    <el-input v-model="formDataProduct.consigneeMobile" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="地址选择" required>
                    <el-cascader
                        ref="cascaderAddr"
                        placeholder="请选择省市区"
                        v-model="cityValueProduct"
                        :options="addressCityList"
                        :props="{value:'Id',label:'Name',children:'VoList'}"
                        @change="handleCasChangeProduct"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" required>
                    <el-input v-model="formDataProduct.consigneeAddress"></el-input>
                </el-form-item>
<!--                <el-form-item label="配送日期" required>
                    <el-date-picker v-model="formDataProduct.deliverDateExpect" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>-->
            </el-form>
            <el-divider></el-divider>
            <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="addProduct()">加商品</el-button>
            <el-table :data="formDataProduct.newProducts" border size="mini">
                <el-table-column type="index" label="序号" align="center"></el-table-column>
                <el-table-column label="商品选择" align="center">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.cbarcode" clearable @change="selectProduct($event,scope.row)" placeholder="选择商品" filterable size="mini">
                            <el-option
                                v-for="item in productList"
                                :key="item.cbarcode"
                                :label="item.value + '(' + item.cbarcode + ')'"
                                :value="item.cbarcode">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="数量" align="center">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.productNum"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" icon="el-icon-delete"
                                   @click="delProduct(scope.$index)" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="handleClose()">取 消</el-button>
                <el-button type="primary" size="mini" @click="saveProduct(1)">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 换货弹窗 -->
<!--        <el-dialog :fullscreen="true"
                   title="换货"
                   :visible="huanhuoShow" @close="handleClose">
            <el-form :model="form" :inline="true" class="demo-form-inline select-wrap" size="mini">
                <el-form-item label="期望配送日期">
                    <el-date-picker v-model="form.deliverDateExpect" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </el-form>
            <p>原商品</p><br>
            <el-table border :data="oldProducts" size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="BarCode" prop="productCbarcode"></el-table-column>
                <el-table-column label="商品名称" prop="productName"></el-table-column>
                <el-table-column label="原商品数量" prop="productNum"></el-table-column>
                <el-table-column label="改后商品数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.reduceCount" :min=0 :max="scope.row.productNum"></el-input-number>
                        <span style="color:red">*请注意这里，原商品保留几个</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-divider></el-divider>
            <p>新商品</p>
            <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="addProduct()">加商品</el-button>
            <el-table :data="newProducts" border size="mini" >
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="商品选择">
                    <template slot-scope="scope" >
                        <el-select size="mini" v-model="scope.row.saleId" @change="selectProduct($event,scope.row)" filterable clearable placeholder="选择商品">
                            <el-option
                                v-for="item in productList"
                                :key="item.cbarcode"
                                :label="item.value + '(' + item.cbarcode + ')'"
                                :value="item.cbarcode">
                            </el-option>
                        </el-select>
                        &lt;!&ndash;                        <el-select size="mini" v-model="scope.row.saleId" v-if="scope.row.type==2" clearable placeholder="选择套装">&ndash;&gt;
                        &lt;!&ndash;                            <el-option&ndash;&gt;
                        &lt;!&ndash;                                    v-for="item in groupList"&ndash;&gt;
                        &lt;!&ndash;                                    :key="item.groupId"&ndash;&gt;
                        &lt;!&ndash;                                    :label="item.groupName"&ndash;&gt;
                        &lt;!&ndash;                                    :value="item.groupId">&ndash;&gt;
                        &lt;!&ndash;                            </el-option>&ndash;&gt;
                        &lt;!&ndash;                        </el-select>&ndash;&gt;
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.saleCount" :min=1 :max=999></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="delProduct(scope.$index)" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="huanhuoShow = false">取 消</el-button>
                <el-button type="primary" size="mini" @click="saveProduct()">确 定</el-button>
            </div>
        </el-dialog>-->
        <!-- 修改商品弹窗 -->
        <el-dialog :fullscreen="true" title="修改商品：只能添加赠品，不能删除和修改原商品" :visible="gaipinShow" @close="handleClose">
            <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="addEditProduct()">加商品</el-button>
            <el-table :data="editProducts" border size="mini" >
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column label="商品选择">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.cbarcode" :disabled="scope.row.isOld==1" clearable @change="selectProductForEditProduct($event,scope.row)" placeholder="选择商品" filterable size="mini">
                            <el-option
                                v-for="item in productList"
                                :key="item.cbarcode"
                                :label="item.value + '(' + item.cbarcode + ')'"
                                :value="item.cbarcode">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.productNum" :min=1 :max=999 :disabled="scope.row.isOld==1"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="delProduct(scope.$index)" :disabled="scope.row.isOld==1" circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button size="mini" @click="handleClose()">取 消</el-button>
                <el-button type="primary" size="mini" @click="saveProduct(3)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default{
    data(){
        return {
            externalOrderNo:this.$route.params.externalOrderNo,
            orderDetailInfo:{},
            pickedup: false,
            isWeixinOrder: true,
            orderEditAddr:false,
            orderEditDate:false,
            addressCityList: [],
            cityValue: '',
            cityValueProduct: '',
            formDataDelivery: {
                externalOrderNo: '',
                deliverDate: '',
                customerId: '',
            },
            formDataAddr: {
                externalOrderNo: '',
                customerId: '',
                consigneeMobile: '',
                consigneeName: '',
                consigneeAddress: '',
                consigneeAreaid: null,
                consigneeAreaName: null,
                consigneeCityName: null,
                consigneeProvinceName: null,
            },
            productList:[],

            bufaShow:false,
            huanhuoShow:false,
            gaipinShow:false,
            formDataProduct:{
                orderNo:"",
                optType:"",
                consigneeMobile: '',
                consigneeName: '',
                consigneeAddress: '',
                consigneeAreaid: null,
                consigneeAreaName: null,
                consigneeCityName: null,
                consigneeProvinceName: null,
                deliverDateExpect:"",
                oldProducts:[],
                newProducts:[],
                consigneeRemark:''
            },
            editProducts:[],
            routers:[{time:'',context:'无'}]
        }
    },

    mounted(){
        this.initList();
        this.getProducts();
        this.getAddressCityList();
    },

    methods:{
        returnHistory(){
            window.history.go(-1)
        },
        handleClose(){
            this.bufaShow = false;
            this.huanhuoShow = false;
            this.gaipinShow = false;
            this.orderEditAddr = false;
            this.orderEditDate = false;
            this.editProducts = [];
            this.resetProductsForm();
            this.resetDeliveryForm();
            this.resetAddrForm();
        },
        getProducts(){
            this.$apiGet('lechun-csms/bdProduct/getProducts').then(res => {
                this.productList = res;
            });
        },
        getAddressCityList() {
            this.$apiGet('lechun-customers/customer/getProvinceCityAreaByErp', {
                transportType: -1,
                isLongPeriodBuy: 0
            }).then(res => {
                console.log(res)
                let add = res.provinceVoList
                let newArr = add.map(item => {
                    item = this.setCityKey(item)
                    item.VoList = item.VoList.map(item1 => {
                        item1 = this.setCityKey(item1)
                        if (item1.VoList != null) {
                            item1.VoList = item1.VoList.map(item2 => {
                                item2 = this.setCityKey(item2)
                                return item2
                            })
                        }
                        return item1
                    })
                    return item
                })
                this.addressCityList = newArr
            });
        },
        initList() {
            this.$apiGet('lechun-csms/order/getOrderDetail',{orderNo:this.externalOrderNo}).then(res => {
                console.log('订单详情',res);
                this.orderDetailInfo = res;
                this.pickedup = this.orderDetailInfo.orderFlowSign == 1 || this.orderDetailInfo.orderFlowSign == 3;
                this.isWeixinOrder = this.orderDetailInfo.shopId == '1325743461067264000' || this.orderDetailInfo.shopId == '1325743461067264001' || this.orderDetailInfo.shopId == '1435846040181936128';
                if(this.orderDetailInfo.wayNo != null && this.orderDetailInfo.wayNo != ''){
                    this.getOriginRouterInfo(this.orderDetailInfo.wayNo);
                }
            });
        },
        openDialogBuFa(){
            this.bufaShow = true;
            this.formDataProduct = {
                orderNo: this.orderDetailInfo.orderNo,
                optType:"",
                consigneeMobile: this.orderDetailInfo.consigneePhone,
                consigneeName: this.orderDetailInfo.consigneeName,
                consigneeAddress: this.orderDetailInfo.consigneeAddr,
                consigneeAreaid: this.orderDetailInfo.consigneeAreaId,
                consigneeAreaName: this.orderDetailInfo.consigneeAreaName,
                consigneeCityName: this.orderDetailInfo.consigneeCityName,
                consigneeProvinceName: this.orderDetailInfo.consigneeProvinceName,
                deliverDateExpect:"",
                oldProducts:[],
                newProducts:[],
                consigneeRemark:'漏发'
            };
            this.openDialogAddr();
            if(this.formDataProduct.consigneeAreaid == null || this.formDataProduct.consigneeAreaid == ''){
                if(this.cityValueProduct != null){
                    this.formDataProduct.consigneeAreaid = this.cityValueProduct[2];
                } else {
                    console.log("无法获取到区ID");
                }
            }
            // 补发要带上原来的商品，默认数量0
            this.orderDetailInfo.orderProducts.forEach((orderPro) => {
                this.productList.forEach((pro) => {
                    if(pro.cbarcode == orderPro.cbarcode) {
                        this.formDataProduct.newProducts.push({
                            productId: pro.cguid,
                            productName: pro.cname,
                            cbarcode: orderPro.cbarcode,
                            productNum: 0,
                            sellPrice: 0,
                            isPromotion: 0,
                            iservice: pro.iservice
                        });
                    }
                });
            });
        },
        openDialogHuanHuo(){
            this.initOldProducts();
            this.huanhuoShow = true;
        },
        openDialogGaiPin(){
            for (var i = 0; i < this.orderDetailInfo.orderProducts.length; i++) {
                this.editProducts.push({
                    detailId: this.orderDetailInfo.orderProducts[i].detailId,
                    oid: this.orderDetailInfo.orderProducts[i].oid,
                    cguid: this.orderDetailInfo.orderProducts[i].cguid,
                    cbarcode: this.orderDetailInfo.orderProducts[i].cbarcode,
                    productName: this.orderDetailInfo.orderProducts[i].productName,
                    oldProductNum: this.orderDetailInfo.orderProducts[i].productNum,
                    productNum: this.orderDetailInfo.orderProducts[i].productNum,
                    sellPrice: this.orderDetailInfo.orderProducts[i].productPrice,
                    iservice: this.orderDetailInfo.orderProducts[i].detailId,
                    isPromotion: this.orderDetailInfo.orderProducts[i].detailId,
                    isOld: 1
                });
            }
            console.log("editProducts",this.editProducts)
            this.gaipinShow = true;
        },
        openDialogAddr(){
            var provinceId, cityId, areaId;
            for (var i = 0; i < this.addressCityList.length; i++) {
                if (this.addressCityList[i].Name == this.formDataProduct.consigneeProvinceName) {
                    provinceId = this.addressCityList[i].Id;
                    var citys = this.addressCityList[i].VoList;
                    for (var j = 0; j < citys.length; j++) {
                        if (citys[j].Name == this.formDataProduct.consigneeCityName) {
                            cityId = citys[j].Id;
                            var areas = citys[j].VoList;
                            for (var k = 0; k < areas.length; k++) {
                                if (areas[k].Name == this.formDataProduct.consigneeAreaName) {
                                    areaId = areas[k].Id;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            this.cityValueProduct = [provinceId, cityId, areaId];
        },
        handleCasChangeProduct(val) {
            console.log(val)
            this.formDataProduct.consigneeAreaid = val.length >= 3 ? val[2] : '';
            var provinceName = '';
            var cityName = '';
            var areaName = '';
            var breakFlag = false;
            for (var i = 0; i < this.addressCityList.length; i++) {
                for (var j = 0; j < this.addressCityList[i].VoList.length; j++) {
                    if (this.addressCityList[i].VoList[j].VoList != null) {
                        for (var k = 0; k < this.addressCityList[i].VoList[j].VoList.length; k++) {
                            if (this.formDataProduct.consigneeAreaid == this.addressCityList[i].VoList[j].VoList[k].Id) {
                                areaName = this.addressCityList[i].VoList[j].VoList[k].Name;
                                cityName = this.addressCityList[i].VoList[j].Name;
                                provinceName = this.addressCityList[i].Name;
                                breakFlag = true;
                                break;
                            }
                        }
                    }
                    if (breakFlag) {
                        break;
                    }
                }
                if (breakFlag) {
                    break;
                }
            }
            this.formDataProduct.consigneeAreaName = areaName;
            this.formDataProduct.consigneeCityName = cityName;
            this.formDataProduct.consigneeProvinceName = provinceName;
            console.log('formDataProduct', this.formDataProduct);
        },
        initOldProducts(){
            // this.oldProducts = this.orderDetailInfo.orderProducts;

            // this.newProducts = [];
        },
        orderFlow() {
            this.$confirm('确定继续流转吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$apiGet('lechun-csms/orderApi/orderFlow', {'orderNo': this.orderDetailInfo.orderNo}).then(res => {
                    this.$message({
                        message: '流转成功',
                        type: 'success'
                    });
                    this.initList();
                });
            }).catch(() => {

            });
        },
        setRemark() {
            this.$prompt('请输入备注', '提示', {
                inputValue: this.orderDetailInfo.remark,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({value}) => {
                this.$apiGet('lechun-csms/orderApi/saveRemark', {
                    'externalOrderNo': this.orderDetailInfo.orderNo,
                    'remark': value
                }).then(v => {
                    this.$message({
                        message: '拦单成功',
                        type: 'success'
                    });
                    this.initList();
                });
            }).catch(() => {

            });
        },
        toEditDeliveryTime() {
            this.orderEditDate = true;
            this.formDataDelivery.externalOrderNo = this.orderDetailInfo.orderNo;
            this.formDataDelivery.deliverDate = this.orderDetailInfo.deliverDate;
        },
        deliveryDateSubmit() {
            var formJson = {
                externalOrderNo: this.formDataDelivery.externalOrderNo,
                deliverDate: this.formDataDelivery.deliverDate
            };
            this.$apiGet('lechun-csms/orderApi/editOrderDeliveryTime', {editOrderParam: JSON.stringify(formJson)}).then(res => {
                this.orderEditDate = false;
                this.$message({
                    message: '配送日期保存成功',
                    type: 'success'
                });
                this.initList();
                this.resetDeliveryForm();
            })
        },
        resetDeliveryForm() {
            this.formDataDelivery.deliverDate = '';
            this.formDataDelivery.customerId = '';
            this.formDataDelivery.externalOrderNo = '';
        },
        setCityKey(obj) {
            let newObj = {}
            let reg = /Name|Id|VoList/
            for (let key in obj) {
                if (reg.test(key)) {
                    newObj[key.match(reg)[0]] = obj[key]
                }
            }
            return newObj
        },
        //修改地址
        editAddress() {
            this.orderEditAddr = true;
            this.formDataAddr.externalOrderNo = this.orderDetailInfo.orderNo;
            this.formDataAddr.customerId = this.orderDetailInfo.buyerId;
            this.formDataAddr.consigneeMobile = this.orderDetailInfo.consigneePhone;
            this.formDataAddr.consigneeName = this.orderDetailInfo.consigneeName;
            this.formDataAddr.consigneeAddress = this.orderDetailInfo.consigneeAddr;
            this.formDataAddr.consigneeAreaid = this.orderDetailInfo.consigneeAreaId;
            this.formDataAddr.consigneeAreaName = this.orderDetailInfo.consigneeAreaName;
            this.formDataAddr.consigneeCityName = this.orderDetailInfo.consigneeCityName;
            this.formDataAddr.consigneeProvinceName = this.orderDetailInfo.consigneeProvinceName;
            var provinceId, cityId, areaId;
            for (var i = 0; i < this.addressCityList.length; i++) {
                if (this.addressCityList[i].Name == this.formDataAddr.consigneeProvinceName) {
                    provinceId = this.addressCityList[i].Id;
                    var citys = this.addressCityList[i].VoList;
                    for (var j = 0; j < citys.length; j++) {
                        if (citys[j].Name == this.formDataAddr.consigneeCityName) {
                            cityId = citys[j].Id;
                            var areas = citys[j].VoList;
                            for (var k = 0; k < areas.length; k++) {
                                if (areas[k].Name == this.formDataAddr.consigneeAreaName) {
                                    areaId = areas[k].Id;
                                    break;
                                }
                            }
                            break;
                        }
                    }
                    break;
                }
            }
            this.cityValue = [provinceId, cityId, areaId];
        },
        handleCasChange(val) {
            console.log(val)
            this.formDataAddr.consigneeAreaid = val.length >= 3 ? val[2] : '';
            var provinceName = '';
            var cityName = '';
            var areaName = '';
            var breakFlag = false;
            for (var i = 0; i < this.addressCityList.length; i++) {
                for (var j = 0; j < this.addressCityList[i].VoList.length; j++) {
                    if (this.addressCityList[i].VoList[j].VoList != null) {
                        for (var k = 0; k < this.addressCityList[i].VoList[j].VoList.length; k++) {
                            if (this.formDataAddr.consigneeAreaid == this.addressCityList[i].VoList[j].VoList[k].Id) {
                                areaName = this.addressCityList[i].VoList[j].VoList[k].Name;
                                cityName = this.addressCityList[i].VoList[j].Name;
                                provinceName = this.addressCityList[i].Name;
                                breakFlag = true;
                                break;
                            }
                        }
                    }
                    if (breakFlag) {
                        break;
                    }
                }
                if (breakFlag) {
                    break;
                }
            }
            this.formDataAddr.consigneeAreaName = areaName;
            this.formDataAddr.consigneeCityName = cityName;
            this.formDataAddr.consigneeProvinceName = provinceName;
            console.log('formDataAddr', this.formDataAddr);
        },
        //地址提交
        addressSubmit() {
            // if (this.formDataAddr.consigneeMobile.length != 11) {
            //     this.$notify({
            //         title: '警告',
            //         message: '请输入正确的手机号',
            //         type: 'warning'
            //     });
            //     return false
            // }
            console.log('formJson', JSON.stringify(this.formDataAddr));
            this.$apiGet('lechun-csms/orderApi/editOrderAddr', {editOrderParam: JSON.stringify(this.formDataAddr)}).then(res => {
                this.drawerShow = false;
                this.$message({
                    message: '恭喜你，地址保存成功',
                    type: 'success'
                });
                this.initList();
                this.resetAddrForm()
            })
        },
        resetAddrForm() {
            this.formDataAddr.externalOrderNo = '';
            this.formDataAddr.customerId = '';
            this.formDataAddr.consigneeMobile = '';
            this.formDataAddr.consigneeName = '';
            this.formDataAddr.consigneeAddress = '';
            this.formDataAddr.consigneeAreaid = '';
            this.formDataAddr.consigneeAreaName = '';
            this.formDataAddr.consigneeCityName = '';
            this.formDataAddr.consigneeProvinceName = '';
        },
        toRefund(){
            if (this.orderDetailInfo.orderSource == 26
                || this.orderDetailInfo.orderSource == 28
                || this.orderDetailInfo.orderSource == 29
                || this.orderDetailInfo.orderSource == 30
                || this.orderDetailInfo.orderSource == 31
                || this.orderDetailInfo.orderSource == 32
                || this.orderDetailInfo.orderSource == 33
                || this.orderDetailInfo.orderSource == 34
                || this.orderDetailInfo.orderSource == 36) {
                this.$confirm('预付卡实物订单需要在用户信息里的预付卡tab页取消', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({
                        path: '/userDetail/:customerId',
                        name: 'refundsDetail',
                        params: {id: this.orderDetailInfo.buyerId}
                    })
                }).catch(() => {
                    this.$router.push({
                        path: '/userDetail/:customerId',
                        name: 'refundsDetail',
                        params: {id: this.orderDetailInfo.buyerId}
                    })
                });
            } else {
                this.$router.push({
                    path: '/refundsList/:externalOrderNo/:pageType/:orderSource',
                    name: 'refundsDetail',
                    params: {orderNo: this.orderDetailInfo.orderNo, pageType: 1, orderSource: this.orderDetailInfo.orderSource}
                });
            }
        },
        getOriginRouterInfo(wayNo){
            this.$apiGet('lechun-bi/order/getOriginRouterInfo',{'expressNo':wayNo}).then(res => {
                this.routers = res.data;
                console.log('物流信息',this.routers.data);
            });
        },
        toBuyer(id) {
            this.$router.push({name: 'userDetail', params: {id: id}})
        },
        resetProductsForm(){
            this.oldProducts = [];
            this.newProducts = [];
        },
        addProduct(){
            this.formDataProduct.newProducts.push({
                productId : '',
                productName : '',
                cbarcode: '',
                productNum: 1,
                sellPrice : 0,
                isPromotion: 0,
                iservice: 0
            })
        },
        addEditProduct(){
            this.editProducts.push({
                detailId : '',
                oid : '',
                cguid : '',
                cbarcode : '',
                productName : '',
                oldProductNum : 0,
                productNum : 1,
                sellPrice : 0,
                iservice : 0,
                isPromotion : 0,
                isOld : 0
            });
        },
        selectProduct(event,row){
            this.productList.forEach((pro) => {
                if(pro.cbarcode == event){
                    row.productId = pro.cguid;
                    row.productName = pro.cname;
                    row.sellPrice = 0;
                    row.iservice = pro.iservice;
                }
            });
        },
        selectProductForEditProduct(event,row){
            this.productList.forEach((pro) => {
                if(pro.cbarcode == event){
                    row.productName = pro.cname;
                    row.iservice = pro.iservice;
                    row.cguid = pro.cguid;
                }
            });
        },
        delProduct(index) {
            this.formDataProduct.newProducts.splice(index, 1)
        },
        // formDataProduct:{
        //     orderNo:"",
        //     optType:"",
        //     consigneeMobile: '',
        //     consigneeName: '',
        //     consigneeAddress: '',
        //     consigneeAreaid: null,
        //     consigneeAreaName: null,
        //     consigneeCityName: null,
        //     consigneeProvinceName: null,
        //     deliverDateExpect:"",
        //     oldProducts:[],
        //     newProducts:[],
        // },
        saveProduct(optType){
            this.formDataProduct.optType = optType;
            if(optType == 1 || optType == 2){//补发、换货
                if(this.formDataProduct.orderNo == ""){
                    this.$notify({title: '警告',message: '系统错误，无法获取订单号',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeMobile == ''){
                    this.$notify({title: '警告',message: '请填写收货人手机号',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeMobile.indexOf('*') != -1){
                    this.$notify({title: '警告',message: '请填写真实手机号，不可以带*号',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeName == ''){
                    this.$notify({title: '警告',message: '请填写收货人',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeName.indexOf('*') != -1){
                    this.$notify({title: '警告',message: '请填写真实收货人，不可以带*号',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeAreaid == null || this.formDataProduct.consigneeAreaName == null
                    || this.formDataProduct.consigneeCityName == null || this.formDataProduct.consigneeProvinceName == null){
                    this.$notify({title: '警告',message: '请选择省市区',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeAddress == ''){
                    this.$notify({title: '警告',message: '请填写详细地址',type: 'warning'});
                    return false;
                }
                if(this.formDataProduct.consigneeAddress.indexOf('*') != -1){
                    this.$notify({title: '警告',message: '请填写真实详细地址，不可以带*号',type: 'warning'});
                    return false;
                }
                // if(this.formDataProduct.deliverDateExpect == ''){
                //     this.$notify({title: '警告',message: '请填写配送日期',type: 'warning'});
                //     return false;
                // }
                if(this.formDataProduct.newProducts.length == 0){
                    this.$notify({title: '警告',message: '请选择新的商品',type: 'warning'});
                    return false;
                }
                this.formDataProduct.newProducts.forEach((orderPro) => {
                    if(orderPro.productNum == 0){
                        this.$notify({title: '警告',message: '请删除数量为0的商品，或修改数量',type: 'warning'});
                        return false;
                    }
                })
                this.$apiPost('lechun-csms/apply/createApply',this.formDataProduct).then(res=>{
                    this.$message({
                        showClose: true,
                        message: res,
                        type: 'success'
                    });
                    this.handleClose();
                    this.initList();
                });
            } else if(optType == 3){// 修改商品
                var hasEditProduct = false;
                this.editProducts.forEach((product) => {
                    if(product.isOld == 0){
                        hasEditProduct = true;
                    }
                });
                if(!hasEditProduct){
                    if(orderPro.productNum == 0){
                        this.$notify({title: '警告',message: '没有添加新的赠品',type: 'warning'});
                        return false;
                    }
                }
                console.log("orderNo",this.orderDetailInfo.orderNo);
                console.log("editProducts",this.editProducts);
                this.$apiPost('lechun-csms/orderApi/editOrderProductsNew?orderNo=' + this.orderDetailInfo.orderNo,JSON.stringify(this.editProducts)).then(res=>{
                    this.$message({
                        showClose: true,
                        message: res,
                        type: 'success'
                    });
                    this.handleClose();
                    this.initList();
                });
            }
        }
    }
}
</script>

<style lang="scss" rel="stylesheet/scss">

.order-detail-info{
    .box-card{
        margin-bottom: 10px;
    }
    .el-card__header{
        padding: 10px;
    }
    .el-card__body{
        padding: 0px;
    }
    .popover-title{
        margin: 0 0 15px 0;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width: 100%;
    }
    .el-tag{
        margin-right: 10px;
    }


    //.red .el-form-item__label{
    //    color: red;
    //}
    //.el-input{
    //    width: 100%;
    //    height: 32px;
    //}
    //.el-tag{
    //    width: 100%;
    //    height: 32px;
    //}
    //.el-avatar{
    //    display: block;
    //    margin: 0 auto;
    //}
}
.order-detail-popover{
    box-shadow: 0 2px 12px 0 rgba(52,62,77,.7);
    .el-cascader--mini{
        width: 100%;
    }
}
</style>
