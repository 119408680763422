<template>
        <defaultSec :title="'< 订单详情'" :returnState="true">
            <orderDetailInfo></orderDetailInfo>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import orderDetailInfo from '@/components/orderSystem/orderDetailInfo'
    import orderProductInfo from '@/components/orderSystem/orderProductInfo'
    export default {
        data() {
            return {

            }
        },
        components:{
            defaultSec,orderDetailInfo,orderProductInfo
        },
        mounted(){
        },
        methods: {

        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">

</style>
